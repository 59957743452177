import type { CreateCustomerInput, LoginMutationVariables, RequestPasswordResetMutationVariables, ResetPasswordMutationVariables, VerifyCustomerAccountMutationVariables } from '#graphql-operations'

export const useAuth = () => useNuxtApp().$auth

export async function authLogin(body: LoginMutationVariables) {
  const localePath = useLocalePath()
  const { data } = await useGraphqlMutation('login', body)

  if (isGraphQLError(data.login))
    throw new Error(data.login.message)

  const { redirectTo } = useRoute().query
  await useAuth().updateSession()
  await navigateTo(redirectTo || localePath('/'))
}

export async function authVerifyEmail(body: VerifyCustomerAccountMutationVariables) {
  const localePath = useLocalePath()
  // NOTE: This acts as authRegister, because email verification is required
  await useGraphqlMutation('verifyCustomerAccount', body)
  await useAuth().updateSession()
  const { redirectTo } = useRoute().query
  await navigateTo(redirectTo || localePath('/'))
}

export async function authRegister(body: CreateCustomerInput) {
  await useGraphqlMutation('registerCustomerAccount', { input: body })
  // TODO: When no email verification is required, login the user
  // return await authLogin(body)
}

export async function authLogout() {
  const store = useStore()
  await useGraphqlMutation('logout')
  store.value.activeCustomer = store.value.activeOrder = null
  await useAuth().updateSession()
}

export async function authForgotPassword(body: RequestPasswordResetMutationVariables) {
  await useGraphqlMutation('requestPasswordReset', body)
}

export async function authResetPassword(body: ResetPasswordMutationVariables) {
  const localePath = useLocalePath()
  await useGraphqlMutation('resetPassword', body)
  const { redirectTo } = useRoute().query
  await useAuth().updateSession()
  await navigateTo(redirectTo || localePath('/'))
}
